$(document).foundation();

$('[data-tabs]').on('change.zf.tabs', function () {
      new Foundation.Equalizer($('.tabs-panel.is-active .row')).applyHeight();
      if (typeof initialise !== 'undefined' && $.isFunction(initialise)) {
            initialise();
      }
}); 

$(document).ready(function() {
	if ($('#main-carousel').length > 0) {
		$('#main-carousel').owlCarousel({
			loop: true,
			margin: 0,
			nav: false,
			dots: false,
			autoplay:true,
			autoplayTimeout:10000,
			autoplayHoverPause:true,
			responsive:{
				0:{ items:1 }
			}
		});
	};
	$(".title-bar").click(function() {
		$('.title-bar').foundation('toggleMenu');
	});
	$(".menu-icon").click(function() {
		$('.title-bar').foundation('toggleMenu');
	});
});


